var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('with-side-navigations',{attrs:{"side":"left"},scopedSlots:_vm._u([{key:"sideNavigation",fn:function(){return [_c('clients-sidebar')]},proxy:true}])},[_c('div',{staticClass:"flex justify-between pb-24"},[_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"flex flex-wrap gap-2 items-center justify-between mb-2"},[_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(_vm._s(_vm.$t('clients-view.clients')))]),_c('filter-toggler',{staticClass:"ml-4"})],1),(_vm.hasAdminRights)?_c('router-link',{attrs:{"to":{ name: 'AddClient' }}},[_c('btn',{staticClass:"w-26"},[_vm._v(_vm._s(_vm.$t('common.add')))])],1):_vm._e()],1),_c('tabs',[_c('tab',{attrs:{"name":_vm.$t('clients-view.all'),"selected":""}},[_c('fetch-data',{attrs:{"url":"organizations","params":_vm.computedQuery,"force-update":_vm.listUpdatesCounter,"debounce":_vm.FILTER_DEBOUNCE},on:{"data-deliver":_vm.updateList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [_c('div',{staticClass:"container"},[(isLoading || _vm.deletionInProgress)?_c('div',{staticClass:"spinner-area"},[_c('icon-spinner',{staticClass:"spinner stroke-primary",attrs:{"width":"64px","height":"64px"}})],1):_vm._e(),(_vm.clientsList.length)?_c('div',{staticClass:"flex flex-wrap w-full",class:{ 'opacity-50': isLoading || _vm.deletionInProgress }},_vm._l((_vm.clientsList),function(client){return _c('card',{key:client.id,staticClass:"mr-4 mt-4",attrs:{"link":{ name: 'ClientDetails', params: { id: client.id } },"image":client.cdnLogoUrl,"alt":_vm.$t('common.client-alt', {
                      name: client.name,
                    }),"context-menu":_vm.hasAdminRights && {
                      edit: {
                        name: _vm.$t('common.edit'),
                        handler: function () {
                          _vm.onEdit(client.id);
                        },
                      },
                      delete: {
                        name: _vm.$t('common.delete'),
                        modal: _vm.getConfirmModalObject('client', client.name),
                        handler: function () {
                          _vm.onDelete(client.id);
                        },
                      },
                    }}},[_vm._v(" "+_vm._s(client.name)+" ")])}),1):_vm._e(),_c('div',{staticClass:"flex w-full mt-4 mb-3 justify-center"},[(_vm.clientsList.length)?_c('pagination',{staticClass:"mt-2",attrs:{"page-count":_vm.lastPage,"current-page":_vm.computedQuery.page || 1},on:{"change-page":_vm.changePage}}):_vm._e()],1),(pagination)?_c('entries-counter',{attrs:{"url":"object-types","to":pagination.to,"from":pagination.from,"filtered-entries":pagination.total}}):_vm._e()],1)]}}])})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }