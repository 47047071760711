import Btn from '@/components/Button/Button.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import Card from '@/components/Card/Card.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import ClientsSidebar from '@/views/Dashboard/Clients/ClientsSidebar.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import EntriesCounter from '@/components/Table/EntriesCounter.vue';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { CLIENT_TYPE } from '@/store/modules/user';
import { useConfirmModal, useRouteQuery, useCardPagination, } from '@/composables';
import { computed, defineComponent, ref, } from '@vue/composition-api/dist/vue-composition-api';
import { deleteOrganization, } from '@/api/requests/organizations';
import { FILTER_DEBOUNCE } from '@/constants/filters';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useGetters } = createNamespacedHelpers('user');
export default defineComponent({
    name: 'Clients',
    components: {
        Btn,
        Tabs,
        Tab,
        Card,
        WithSideNavigations,
        ClientsSidebar,
        FetchData,
        IconSpinner,
        Pagination,
        EntriesCounter,
        FilterToggler,
    },
    setup(_, ctx) {
        const { changePage } = useCardPagination(ctx);
        const { getConfirmModalObject } = useConfirmModal(ctx);
        const { routeQuery } = useRouteQuery(ctx);
        const { hasAdminRights } = useGetters(['hasAdminRights']);
        const clientsList = ref([]);
        const deletionInProgress = ref(false);
        const listUpdatesCounter = ref(0);
        const lastPage = ref(0);
        const computedQuery = computed(() => {
            return {
                ...routeQuery.value,
                type: CLIENT_TYPE,
            };
        });
        const updateList = (data) => {
            clientsList.value = data.results;
            lastPage.value = data.pagination.lastPage;
        };
        const onEdit = (id) => {
            ctx.root.$router.push({ name: 'EditClient', params: { id } });
        };
        const onDelete = async (id) => {
            try {
                deletionInProgress.value = true;
                await deleteOrganization(id);
                listUpdatesCounter.value++;
                ctx.root.$toast.success(ctx.root.$t('clients-view.deleted-successfully'));
            }
            finally {
                deletionInProgress.value = false;
            }
        };
        return {
            FILTER_DEBOUNCE,
            computedQuery,
            hasAdminRights,
            clientsList,
            deletionInProgress,
            listUpdatesCounter,
            lastPage,
            changePage,
            updateList,
            onEdit,
            onDelete,
            getConfirmModalObject,
        };
    },
});
